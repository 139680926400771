$GraphikRegular: 'Graphik Regular';
$GraphikMedium: 'Graphik Medium';
$GraphikBold: 'Graphik Bold';


// -- COLORS -- //

$white: #ffffff;
$blueOpacity: #2d3f4de0;
$blueDark: #243746;
$blueDarkLight: #344b5c;
$blueDarkHeavy: #171e27;
$red: #ff6d6d;
$green: #3DAE2B;
$greenLite: #EFFFEC;
$greenText: #838383;

@font-face {
  font-family: 'Graphik Regular';
  src: url('../../fonts/font_regular.ttf');
}
@font-face {
  font-family: 'Graphik Medium';
  src: url('../../fonts/font_medium.ttf');
}
@font-face {
  font-family: 'Graphik Bold';
  src: url('../../fonts/font_bold.ttf');
}
