@import './sass/base/base.scss';

body {
  height: 100vh;
  background-color: $blueDark;
  font-family: 'Graphik Regular';
  color: $white;
  overflow: hidden;
}

select {
  background-position: right 10px;
  width: 100%;
  height: 3.2rem;
  outline-offset: none;
  outline: none;
  background-color: $blueDarkLight;
  font-family: $GraphikRegular;
  font-size: 1.3rem;
  color: white;
  border: none;
  padding: 0 0.5rem;
}

input {
  background-color: #344b5c;
  outline: none;
  border: solid 0.5px #606b74;
  border-radius: 1rem;
  color: $white;
  height: 4rem;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-family: $GraphikRegular;
  &::placeholder {
    color: $white;
  }
}
.button-green {
  display: block;
  width: 100%;
  border: none;
  background-color: $greenLite;
  color: $green;
  font-family: $GraphikBold;
  font-size: 1.8rem;
  height: 4rem;
  border-radius: 0 1.6rem;
  cursor: pointer;
  text-align: center;
}


.swal-modal {
  background-color: $blueDarkHeavy;
  border: none;
  border-radius: .8rem;
  color: $white;
  .swal-button {
    background-color: $red;
    font-family: $GraphikBold;
    padding: 4px 8px;
    &:hover {
      background-color: $red;
    }
  }
  .swal-title, .swal-text {
    font-size: 2rem;
    color: $white;
    margin-bottom: 0;
    text-align: center;
    padding: 10px;
  }
  .swal-content {
    margin-top: 0;
    padding: 30px;
    div {
      column-count: 2;
      text-align: left;
      width: 95%;
      margin: auto;
    }
    p {
      font-family: $GraphikRegular;
      font-size: 1.3rem;
      margin: 0;
      padding: .5rem 0 .5rem 1rem;
      &:nth-child(even) {
        background-color: $blueDarkLight;
      }
      &:nth-child(even) {
        background-color: $blueOpacity;
      }
    }
    span {
      font-family: $GraphikBold;
      padding-right: .5rem;
      font-weight: bold;
    }
  }
  .swal-footer {
    position: absolute;
    margin-top: 0;
    top: 0;
    right: 0;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #606B74 $blueDarkLight;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  // background: $blueDarkLight;
}

*::-webkit-scrollbar-thumb {
  background-color: #606B74;
  border-radius: 30px;
  // border: 0 solid $blueDarkLight;
}