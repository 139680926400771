@import '../../../sass/base/base.scss';

.intro{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blueDark;
    .video-react {
        padding: 20% 0 0 !important;
    }
    .logo{
        animation: appear 2s;
        img {
            width: 30rem;
        }
    }
}