@import '../../../../src/sass/base/variables';

.data__item{
    height: 5.5rem;
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: auto;
    padding: 0 1rem;
    align-items: center;
    color: white;
    font-size: 1.3rem;
    &:nth-child(even) {
        background-color: $blueDarkLight;
    }
    &:nth-child(even) {
        background-color: $blueDark;
    }
}