@import '../../../sass/base/variables';

.btn__item {
    cursor: pointer;
    background-color: $greenLite;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;
    width: 10.9rem;
    border: none;
    border-radius: 1rem;
    color: $blueDark;
    position: relative;
    font-weight: 500;
    border-radius: .7rem;
    padding: .8rem 3.2rem .8rem .8rem;
    height: 2.8rem;
    font-family: $GraphikRegular;
    font-size: 1.3rem;
    width: auto;
    &--icon {
        position: absolute;
        right: .7rem;
        width: 2rem;
        height: 1.8rem;
    }
}
