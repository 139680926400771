.login{
    display: flex;
    height: 100vh;
    &__left{
        background-image: url('../../../img/bg-login.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 50%;
    }
    &__right{
        width: 50%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10rem;
        .logo{
            img {
                width: 25rem;
            }
        }
    }
}