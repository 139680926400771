@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes RightToLeft {
  0% {
    opacity: 0;
    left: 60%;
  }
  100% {
    opacity: 1;
    left: .5%;
  }
}
