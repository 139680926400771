
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .7rem 2.5rem;
    height: 6rem;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
-moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.8);
    gap: 10%;
    .logo{
        img{
            width: 16.4rem;
        }
    }
}