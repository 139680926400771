@import '../../../sass/base/variables';

.info__farm {
  display: flex;
  align-items: center;
  color: $white;
  gap: 2.4rem;
  height: 4rem;
  &--name {
    h2 {
      font-family: $GraphikRegular;
      font-size: 2.2rem;
      font-weight: 400;
      line-height: .9;
      margin: 0;
    }
  }
  &--location {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    p {
      font-family: $GraphikMedium;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
}
