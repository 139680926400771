/**
 * _link.scss
 *
 * These are the styles general.
 *
 * Index
 * -  All site
 *   |- body
 *   |- p
 *   |- e
 *   |- Custom loading
 *   |- Animate loading
 *   |- row
 */

//html
html {
  font-size: 10px;
  box-sizing: border-box;
}

