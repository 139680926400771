@import '../../../sass/base/variables';

.logout--container {
  display: flex;
  align-items: center;
  .logout--btn {
    background-color: $red;
    color: $white;
    border: 0;
    border-radius: .7rem;
    font-family: 'Graphik Bold';
    font-size: 1.2rem;
    margin: 0 .7rem;
    padding: .5rem 1rem;
    cursor: pointer;
  }
}