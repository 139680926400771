@import '../../../../src/sass/base/variables';

.page__home{
    display: flex;
    width: 100%;
    justify-content: center;
    &--left{
        &.interpretation--on {
            width: 65%;
            transition: all 1.5s;
        }
        &.interpretation--off {
            width: 85%;
        }
    }
    &--right{
        display: block;
        width: 100%;
        display: flex;
        position: relative;
        z-index: 1;
        &.interpretation--on {
           width: 35%;
           transition: all 1.5s;
           .interpretation,
            .features {
                width: 45%;
                transition: all 1.5s;
            }
        }
        &.interpretation--off {
            width: 17%;
            transition: all 1.5s;
        }
    }
}