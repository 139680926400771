@import '../../../sass/base/base.scss';

.weather {
  background-color: $blueDarkHeavy;
  border-radius: 0.7rem;
  padding: 1.5rem;
  width: 100%;
  position: absolute;
  top: 2.5rem;
  width: max-content;
  z-index: 2;
  @include mq (md) {
    top: 7rem;
    left: 1rem;
  }
  @include mq (xl) {
    top: 1.5rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .spinner{
    border-radius: 0.7rem;
    background-color: $blueDarkHeavy;
  }
  &--container {
    display: flex;
    justify-content: space-between;
  }
  &--c__top {
    justify-content: flex-start;
    .weather--image {
      height: 2.8rem;
      width: 2.8rem;
      margin-bottom: 0;
    }
    .weather_condition {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      padding-right: 1rem;
    }
  }
  &--c__top,
  &--c__bottom {
    display: flex;
    align-items: center;
    .weather-info {
      font-family: 'Graphik Medium';
      font-size: 1.2rem;
      margin: .2rem 0;
      line-height: 1;
    }
  }
  &--c__bottom {
    justify-content: space-between;
    .weather--temperature,
    .weather--humidity,
    .weather--wind,
    .weather--rainProbability {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 6.2rem;
    }
    .weather--humidity,
    .weather--wind {
      margin: 0 .4rem;
    }
    .weather-info {
      font-family: 'Graphik Medium';
      font-size: 1.2rem;
      margin: .2rem 0;
    }
  }
  &--image {
    margin-bottom: .5rem;
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain;
  }
}