@import '../../../../src/sass/base/variables';

.show--analysis {
  background-color: $blueDarkLight;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  .select__eye--img {
    position: initial;
    margin: 0 0 0 .8rem;
  }
  .select__farm--title {
    margin: .8rem 0 .8rem .8rem;
  }
}