.form__login{
    width: 35rem;
    &--form{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1.6rem;
        &--login{
            margin-top: 8rem;
        }
    }
}