@import '../../../sass/base/base.scss';

.select__cloud{
    &--container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
    }
    &--input {
        box-sizing: border-box;
        width: 100%;
        font-size: 1.4rem;
        border-radius: 0;
        border: none;
        padding: 0 1rem;
        cursor: pointer;
    }
}
.select__eye {
    position: relative;
    &--select{
        padding: 0 0.5rem 0 30px;
    }
    &--img {
        position: absolute;
        top: .7rem;
        left: 0.6rem;
    }
}
