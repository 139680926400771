@import '../../../sass/base/base.scss';

.select__farm {
    display: block;
    margin: 0 auto 1.8rem;
    &--title {
        display: block;
        font-family: $GraphikMedium;
        font-weight: 500;
        font-size: 1.3rem;
        margin: 2rem 0 1.5rem;
    }
    &--titleCrop,
    &--titleLand,
    &--titleNutrient,
    &--titleSatelital {
        position: relative;
        padding-left: 3rem;
        &::before {
            content: '';
            display: block;
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 2rem;
            width: 2rem;
            left: 0;
            top: -.7rem;
            bottom: 0;
            margin: auto;
        }
    }
    &--titleCrop {
        &::before {
            background-image: url('../../../img/plant.svg');
        }
    }
    &--titleLand {
        &::before {
            background-image: url('../../../img/rule.svg');
        }
    }
    &--titleNutrient {
        &::before {
            background-image: url('../../../img/laboratory.svg');
        }  
    }
    &--titleSatelital {
        &::before {
            background-image: url('../../../img/satelital.svg');
        }
    }
}