@import '../../../../src/sass/base/variables';

.statistics {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    &--left {
        width: 200px;
        max-width: 200px;
        .select--var{
            position: relative;
        }
    }
    &--left__data {
        background-color: $blueDarkHeavy;
        border-radius: .7rem;
        margin: .7rem;
        padding: 1rem;
        width: 135px;
        .select__farm--select {
            background-color: transparent;
            border-radius: 1rem;
            font-size: 1.2rem;
            height: 2rem;
            padding: 0 1rem 0 0;
            background-color: $blueDarkHeavy;
        }
        .statistics--ld--text {
            color: $greenText;
            display: block;
            font-size: 1rem;
            margin-bottom: 0;
        }
        .react-datepicker__input-container {
            input {
                background-image: url('../../../img/Calendar.svg');
                background-position: top right;
                background-repeat: no-repeat;
                background-size: 12%;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                font-size: 1.2rem;
                padding: 0;
                height: 2rem;
                width: 100%;
                max-width: 125px;
                cursor: pointer;
            }
        }
    }
    &--right {
        background-color: $blueDarkHeavy;
        border-radius: .7rem;
        margin: .7rem;
        padding: 1rem;
        width: 100%;
    }
}