@import '../../../../src/sass/base/variables';
.features{
    background-color: $blueDark;
    width: 50%;
    height: 88vh;
    margin: 0 0 0 auto;
    overflow-y: scroll;
    width: 100%;
    // max-width: 250px;
    &__btn {
        .btn__principal {
            margin: auto;
        }
    }
    &__group--container {
        border-bottom: 1px solid $blueDarkLight;
        width: 80%;
        margin: auto;
        &:last-child {
            border-bottom: none; 
        }
    }
    .react-datepicker__input-container {
        background-color: $blueDarkLight;
        input {
            background-image: url('../../../img/Calendar.svg');
            background-position: top 8px right;
            background-repeat: no-repeat;
            background-size: 8%;
            background-color: transparent;
            font-size: 1.3rem;
            border: 0;
            height: 3.2rem;
            max-width: 14rem;
            padding: 0 1rem 0 1rem;
            cursor: pointer;
        }
    }
}