@import '../../../../src/sass/base/variables';

.interpretation {
  background-color: $blueDarkLight;
  width: 50%;
  height: calc(88vh);
  box-shadow: inset 0px 11px 10px -10px rgb(27, 27, 27), inset 0px -11px 10px -10px rgb(27, 27, 27);
  padding: 0 2.5rem;
  position: absolute;
  left: .5%;
  opacity: 1;
  overflow: hidden;
  animation: RightToLeft ease-in-out 1.8s;
  z-index: -1;
  &__close {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
  }
  &__close--icon {
    background-color: $red;
    border-radius: .5rem;
    color: $white;
    font-family: $GraphikBold;
    font-size: 1.2rem;
    padding: .4rem .6rem;
    cursor: pointer;
  }
  &__content{
    width: 100%;
    margin: auto;
    font-family: $GraphikRegular;
    overflow: hidden;
    &--title{
      font-size: 1.8rem;
      width: 90%;
      margin: 4rem auto 3rem;
    }
    &--description{
      margin: 0 auto 3.5rem;
      font-size: 1.4rem;
      width: 90%;
    }
  }
  &__items{
    height: 80vh;
    overflow-y: scroll;
    .data__item {
      &:last-child {
        margin-bottom: 15rem;
      }
    }
    
  }
}
