@import '../../../sass/base/base.scss';

.page404 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blueDark;
  &__content {
    .button-green {
      line-height: 2.2;
    }
  }
  &__content--logo {
    position: relative;
  }
  &__content--logo--image {
    width: 30rem;
  }
  &__content--title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 7rem;
  }
}
