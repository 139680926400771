@import '../../../sass/base/variables';

.navbar{
    display: flex;
    align-items: center;
    gap: 4rem;
    .btn__item {
        font-family: $GraphikMedium;
        font-size: 1.4rem;
        padding: 2rem;
        width: 10rem;
        &--icon {
            position: relative;
            right: initial;
        }
    }
}