@import '../../../sass/base/base.scss';

.select__date{
    &--container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
    }
    &--input {
        box-sizing: border-box;
        width: 100%;
        font-size: 1.4rem;
        border-radius: 0;
        border: none;
        padding: 0 1rem;
        cursor: pointer;
    }
}
.random{
    background-color: #3DAE2B;
    border-radius: 0.5rem;
}
.react-datepicker__day--selected {
    background-color: #243746 !important;
}