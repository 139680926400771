@import '../../../../src/sass/base/variables';

.statistics-image {
    display: none;
    background-color: $blueDarkLight;
    border: 2px solid $white;
    width: 220px;
    height: 150px;
    padding: 10px;
    position: relative;
    top: -75px;
    z-index: 1;
    left: 75px;
    img {
        width: 220px;
        height: 150px;
        object-fit: contain;
    }
}